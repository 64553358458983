import React, { useState, useEffect, useRef } from "react";
import { Can } from "../permission/Can";
import SideNav from "../components/Navbar/SideNav";
import StepperList from "../components/utilities/StepperList";
import Avatar from "../assets/images/avatar.jpg";
import { useLocalStore } from "../store";
import { Outlet, useNavigate } from "react-router-dom";
import Nav from "../components/Navbar/Navbar";
import {
  BASE_DOCUMENT_TYPES,
  BASE_VISA_TYPES,
  GET_ORGANIZATION,
  GET_ORGANIZATION_PERMITS,
  ORGANIZATION_DASHBOARD_DATA,
  ORG_GET_ORGANIZATION_BYID,
  PERMIT_PRICE,
} from "../graph-query/queries";
import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import { ability, updateRole } from "../permission/Ability";
import { ORGANIZATION_ID_SUBSRIPTION } from "../graph-query/subscription";
import { OrganizationSidebarLink } from "../utils/sidebarNav";
import { trackPromise } from "react-promise-tracker";

const OrganizationLayout = () => {
  const [activeStep, setActiveStep] = useState("Educational Information");
  // const { currentRole, user } = useLocalStore();
  const isFirstRunRef = useRef(true);
  const isCurrentRef = useRef(true);

  const router = useNavigate();

  const {
    currentRole,
    documentTypes,
    currentOrganization,
    userOrganizationData,
    organizationDashboardData,
    organizationWorkPermits,
    selectedCurrentOrg,
    permitPrice,
    userData,
    user,
    setUserData,
    visaTypes,
    setVisaTypes,
    setDocumentTypes,
    setCurrentRole,
    setPermitPrice,
    setCurrentOrganization,
    setUserOrganizationData,
    setOrganizationWorkPermits,
    setOrganizationDashboardData,
  } = useLocalStore();

  const getuserRole = () => {
    // let currentAuth;
    let _local = localStorage.getItem("work-permit");
    _local = JSON.parse(_local)?.roles;
    console.log(_local);
    setCurrentRole(_local);

    updateRole(_local);
  };

  const t = localStorage.getItem("currentOrg");
  const currentOrgId = JSON.parse(t)?.currentOrg?.id;

  /**
   * @description work permit price
   */
  const [getPermitPrice, {}] = useLazyQuery(PERMIT_PRICE, {
    onCompleted: (d) => {
      setPermitPrice(d?.base_expatriate_work_permit_prices || []);
    },
  });
  /**
   * @description get dashboard data(statstic)
   */
  const [getDashboard, {}] = useLazyQuery(ORGANIZATION_DASHBOARD_DATA, {
    variables: {
      orgID: userOrganizationData[0]?.id,
    },
    onCompleted: (d) => {
      setOrganizationDashboardData(
        d?.expatriate_work_permits_aggregate?.aggregate
      );
    },
  });

  const [getOrg, {}] = useLazyQuery(ORG_GET_ORGANIZATION_BYID, {
    variables: {
      id: currentOrgId,
    },
    onCompleted: (d) => setCurrentOrganization(d?.organizations[0]),
  });
  /**
   * @description get logged user data with assigned organization if there is any
   */
  const [getUserData, { error: userErr }] = useLazyQuery(GET_ORGANIZATION, {
    variables: { eq: JSON.parse(localStorage.getItem("work-permit"))?.userID },
    context: {
      headers: {
        "Content-Type": "application/json",
      },
    },
    onCompleted: async (data) => {
      await setUserOrganizationData(data?.organizations);
      isFirstRunRef.current = false;
      if (data?.organizations?.length >= 1) {
        router("/workspace");
      }
    },
  });

  /**
   * @description get organization requested permits
   */
  const [getRequstedWorkPermit, { loading, error }] = useLazyQuery(
    GET_ORGANIZATION_PERMITS,
    {
      variables: {
        orgID:
          userData?.id || JSON.parse(localStorage.getItem("currentOrg"))?.id, //userOrganizationData?.id ||
      },
      onCompleted: (data) => {
        setOrganizationWorkPermits(data?.expatriate_work_permits);
      },
      onError: (er) => console.log(er),
    }
  );

  const [getDocType, {}] = useLazyQuery(BASE_DOCUMENT_TYPES, {
    variables: {},
    onCompleted: (d) => {
      setDocumentTypes(d?.base_document_types);
    },
  });

  const [getVisaTypes, {}] = useLazyQuery(BASE_VISA_TYPES, {
    onCompleted: (data) => {
      console.log("visa types:", data?.base_visa_types);
      setVisaTypes(data?.base_visa_types);
    },
  });

  useEffect(() => {
    if (visaTypes?.length === 0) {
      getVisaTypes();
    }
  }, [visaTypes, setVisaTypes]);
  //request new data if not found
  useEffect(() => {
    if (userOrganizationData?.length === 0) {
      // Get Data from server
      console.log("getting data from server....");
      getUserData();
    } else {
      return;
    }
  }, [userOrganizationData, setUserOrganizationData]);

  useEffect(() => {
    if (!organizationDashboardData) {
      getDashboard();
    }
  }, [organizationDashboardData, setOrganizationDashboardData]);

  useEffect(() => {
    if (organizationWorkPermits?.length === 0) {
      trackPromise(getRequstedWorkPermit()).finally(() => {});
    }
  }, [organizationWorkPermits, setOrganizationWorkPermits]);

  // useEffect(() => {
  //   // let t = localStorage.getItem("currentOrg");
  //   if (currentOrganization === 0) {
  //     setCurrentOrganization(
  //       userOrganizationData
  //         ?.filter((i) => i?.id === JSON.parse(t)?.id)
  //         ?.map((item) => item)[0]
  //     );
  //     setUserData(
  //       "currentOrg",
  //       JSON.parse(
  //         userOrganizationData
  //           ?.filter((i) => i?.id === JSON.parse(t)?.id)
  //           ?.map((item) => item)[0]
  //       )
  //     );
  //     // isCurrentRef.current = false;
  //   }
  // }, [currentOrganization, setCurrentOrganization]);

  useEffect(() => {
    if (documentTypes.length === 0) {
      getDocType();
    }
  }, [documentTypes, setDocumentTypes]);
  // =========================================================================

  useEffect(() => {
    // if (!isFirstRunRef.current) {
    if (userOrganizationData && !t) {
      setCurrentOrganization(
        userOrganizationData
          ?.filter((i) => i?.id === JSON.parse(t)?.id)
          ?.map((item) => item)
      );
    } else {
      getOrg();
    }
    // }
    // setCurrentOrganization(JSON.parse(t));
  }, [userOrganizationData, setUserOrganizationData]);

  useEffect(() => {
    if (permitPrice?.length === 0) {
      getPermitPrice();
    }
  }, [permitPrice, setPermitPrice]);
  // ========================================================================
  useEffect(() => {
    getuserRole();
  }, []);

  useEffect(() => {
    let l = localStorage.getItem("work-permit");
    if (!l) {
      router("/login");
    }
  }, []);

  return (
    <>
      <Nav
        optionOrg={false}
        Avatar={Avatar}
        userType={currentRole}
        name={userOrganizationData}
      />
      <div className="flex flex-col md:flex-row gap-8 2xl:gap-12 mt-10 md:mt-20 layout items-start">
        <SideNav disabled={!currentOrganization?.review_status}>
          <h2 className="font-bold">
            {JSON.parse(localStorage.getItem("currentOrg"))?.name}
          </h2>
          {OrganizationSidebarLink.map((link) => (
            <Can I="view" a={link.permission}>
              {() => (
                <StepperList
                  _link={link.path}
                  icon={link.icon}
                  stepperText={link.title}
                  disabled={link.disabled}
                  activeClassName="bg-primary text-white"
                />
              )}
            </Can>
          ))}
        </SideNav>
        <div className="w-full">
          {/* <div className="fixed top-1/2 p-7 text-white text-lg -left-12 bg-primary rotate-90">
            Development
          </div> */}
          <Outlet />
        </div>
      </div>

      <div className="fixed bottom-8 right-8">
        {/* <a
          href={`https://t.me/EWPSupport`}
          target="_blank"
          type="button"
          className="bg-primary w-12 h-12 md:w-20 md:h-20 rounded-full flex justify-center heartbeat items-center hover:scale-105 ease-in-out hover:ring-2 hover:ring-offset-2 cursor-pointer"
        >
          <img
            src="https://s2.svgbox.net/social.svg?ic=telegram&color=fff"
            width="22"
            height="22"
          />
        </a> */}
        <div class="floating-button">
          <div class="tooltip">
            <a
              href="https://t.me/EWPSupport"
              target="_blank"
              type="button"
              class="bg-primary w-12 h-12 md:w-20 md:h-20 rounded-full flex justify-center items-center cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105 hover:ring-2 hover:ring-offset-2"
            >
              <img
                src="https://s2.svgbox.net/social.svg?ic=telegram&color=fff"
                width="22"
                height="22"
                alt="Telegram Icon"
              />
            </a>
            <span class="tooltip-text">Join our Telegram channel</span>
          </div>
        </div>
      </div>
      <div className="mt-16">{/* Footer */}</div>
    </>
  );
};

export default OrganizationLayout;
