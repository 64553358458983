import { gql } from "@apollo/client";
import { WORK_PERMIT_TYPES } from "../utils/constants";

export const SEARCH_EXPAT = gql`
  query searchExpat($passport_number: String) {
    expatriates(where: { passport_number: { _eq: $passport_number } }) {
      first_name
      father_name
      grand_father_name
      gender {
        name
      }
      nationality {
        name
      }
      date_of_birth
      marital {
        name
      }
      id
      way_of_entry {
        name
      }
      visa_type {
        name
      }
      visa_number
      skills
      profession_title{name_json}
      passport_number
    }
  }
`;

export const TOTAL_ORGANIZATION = gql`
  query total_organization {
    organizations_aggregate {
      aggregate {
        count
      }
    }
  }
`;
export const TOTAL_ORGANIZATION_ASSIGNED = gql`
  query total_organization($id: uuid = "") {
    assigned_organization_reviewers_aggregate(
      where: { employee_id: { _eq: $id } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const COUNT_TOTAL_PERMIT_ASSGINED = gql`
  query total_permit($id: uuid = "") {
    assigned_workpermit_reviewers_aggregate(
      where: { employee_id: { _eq: $id } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_APPLICANT_REQUEST = gql`
  query GET_APPLICANT_REQUEST {
    work_permit_requests {
      id
      attachments
      father_name
      first_name
      gender
      phone_number
      allowance
      canceled
      created_at
      created_by
      date_of_birth
      employment_date
      emr_number
      entry_date
      passport_number
      submitted
      submitted_at
      tracking_number
      verified
      visa_expiry_date
      occupation_category {
        name
        name_json
        description
        properties
        created_at
      }
      orgnization {
        id
        name
        activity_code
        business_licence_number
        house_number
        email
        town
        tin_number
        telephone
        sector {
          name
        }
      }
      occupation_category {
        name
      }
      work_permit_finance_documents {
        checked
        checked_at
        checked_by_id
        created_at
        files
        id
        reference_number
        verified
        verified_at
        updated_at
      }
      work_permit_request_documents {
        files
        id
        title
        verified
        verified_by
        verified_on
        verification_remark
        document_type {
          file_type
          name
        }
      }
    }
  }
`;

// Get Applicant by ID
export const GET_APPLICANT_REQUEST_BYID = gql`
  query GET_APPLICANT_REQUEST_BYID($id: uuid!) {
    work_permit_requests(where: { id: { _eq: $id } }) {
      id
      attachments
      father_name
      first_name
      gender
      phone_number
      allowance
      canceled
      created_at
      created_by
      date_of_birth
      employment_date
      emr_number
      entry_date
      passport_number
      submitted
      submitted_at
      tracking_number
      verified
      visa_expiry_date
      marital_status
      date_of_birth
      passport_number
      submitted_at
      intended_position
      salary
      passport_issued_date
      passport_expiry_date
      visa_expiry_date
      visa_issued_date
      visa_type
      visa_number
      nationality {
        name
        work_permit_requests {
          visa_number
          visa_type
          verified
          phone_number
          passport_number
          id
          emr_number
          first_name
          father_name
          gender
          phone_number
          emr_number
          tracking_number
          verified
          nationality {
            name
          }
        }
      }
      orgnization {
        id
        name
        activity_code
        business_licence_number
        house_number
        email
        town
        tin_number
        telephone
        sector {
          name
        }
      }
      occupation_category {
        name
      }
      work_permit_finance_documents {
        checked
        checked_at
        checked_by_id
        created_at
        files
        id
        reference_number
        verified
        verified_at
        updated_at
      }
      work_permit_request_documents {
        files
        id
        title
        verified
        verified_by
        verified_on
        verification_remark
        document_type {
          file_type
          name
        }
      }
    }
  }
`;

export const GET_CANCELLED_REQUEST = gql`
  query GET_CANCELLED_REQUEST($type: Boolean) {
    work_permit_requests(where: { canceled: { _eq: $type } }) {
      id
      first_name
      father_name
      nationality {
        name
        id
        work_permit_requests {
          visa_number
          visa_type
          verified
          phone_number
          passport_number
          id
          emr_number
        }
      }
      date_of_birth
      employment_date
      visa_number
      document_checker_id
      finance_document_checker_id
      gender
      visa_issued_date
      marital_status
      allowance
      emr_number
      submitted
      submitted_at
      verified
      canceled
      created_at
      occupation_category {
        name_json
        id
        properties
        created_at
      }
      orgnization {
        name
        organization_type {
          description_json
          id
          name_json
        }
      }
      work_permit_request_documents {
        files
        id
        title
        verified
        verified_by
        verified_on
      }
      work_permit_finance_documents_aggregate {
        nodes {
          reference_number
          checked
          checked_at
          checked_by_id
          created_at
          files
          id
          updated_at
          verified
          verified_at
          work_permit_request {
            attachments
          }
        }
      }
    }
  }
`;

export const GET_CREDENTIAL_INFO = gql`
  query GET_CREDENTIAL_INFO($id: uuid!) {
    credentials(where: { id: { _eq: $id } }) {
      email
      phone_number
      orgnizations {
        id
        email
        review_status
        review_note
      }
      name
    }
  }
`;

// =================================
export const SEARCH_ORGANIZATION_BG_TIN = gql`
  query get_searched_organization(
    $empID: uuid = ""
    $field: String!
    $value: String!
  ) {
    assigned_organization_reviewers(
      where: { employee_id: { _eq: $empID } }
      field: $field
      value: $value
    ) {
      id
      employee_id
      organization {
        business_licence_number
        document_status
        created_at
        user_info {
          id
          email
          name
          __typename
        }
        district {
          id
          name_json
          __typename
        }
        email
        expatriates_count
        fax
        local_employees_count
        house_number
        id
        zone {
          id
          name_json
          __typename
        }
        woreda {
          id
          name_json
          __typename
        }
        tin_number
        subcity {
          id
          name_json
          __typename
        }
        sector {
          id
          name_json
          __typename
        }
        expatriate_work_permit {
          expatriate {
            first_name
            passport_number
            father_name
            gender {
              name
              __typename
            }
            marital {
              name
              __typename
            }
            __typename
          }
          expatriate_work_permit_documents {
            id
            document_type {
              name
              id
              __typename
            }
            review_note
            review_status
            __typename
          }
          __typename
        }
        organization_documents {
          files
          review_status
          review_note
          __typename
        }
        review_status
        review_note
        region {
          id
          name_json
          __typename
        }
        organization_type {
          name_json
          id
          __typename
        }
        name
        pobox
        phone_no
        __typename
      }
      __typename
    }
  }
`;
export const GET_ASSIGNED_ORGANIZATION = gql`
  query get_assgined_organization(
    $limit: Int = 10
    $offset: Int = 0
    $empID: uuid = ""
  ) {
    assigned_organization_reviewers(
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
      where: { employee_id: { _eq: $empID } }
    ) {
      id
      employee_id
      organization {
        business_licence_number
        document_status
        created_at
        user_info {
          id
          email
          name
        }
        district {
          id
          name_json
        }
        email
        expatriates_count
        fax
        local_employees_count
        house_number
        id
        zone {
          id
          name_json
        }
        woreda {
          id
          name_json
        }
        tin_number
        subcity {
          id
          name_json
        }
        sector {
          id
          name_json
        }
        expatriate_work_permit {
          expatriate {
            first_name
            passport_number
            father_name
            gender {
              name
            }
            marital {
              name
            }
          }
          expatriate_work_permit_documents {
            id
            document_type {
              name
              id
            }
            review_note
            review_status
          }
        }
        organization_documents {
          files
          review_status
          review_note
        }
        review_status
        review_note
        region {
          id
          name_json
        }
        organization_type {
          name_json
          id
        }
        name
        pobox
        phone_no
      }
    }
  }
`;

export const GET_ASSIGNED_ORGANIZATION_FILTER = gql`
  query get_assgined_organization($searchInput: String, $empID: uuid = "") {
    assigned_organization_reviewers(
      order_by: { created_at: desc }
      where: {
        employee_id: { _eq: $empID }
        _or: [
          { organization: { tin_number: { _ilike: $searchInput } } }
          { organization: { name: { _ilike: $searchInput } } }
        ]
      }
    ) {
      id
      employee_id
      organization {
        business_licence_number
        document_status
        created_at
        user_info {
          id
          email
          name
        }
        district {
          id
          name_json
        }
        email
        expatriates_count
        fax
        local_employees_count
        house_number
        id
        zone {
          id
          name_json
        }
        woreda {
          id
          name_json
        }
        tin_number
        subcity {
          id
          name_json
        }
        sector {
          id
          name_json
        }
        expatriate_work_permit {
          expatriate {
            first_name
            passport_number
            father_name
            gender {
              name
            }
            marital {
              name
            }
          }
          expatriate_work_permit_documents {
            id
            document_type {
              name
              id
            }
            review_note
            review_status
          }
        }
        organization_documents {
          files
          review_status
          review_note
        }
        review_status
        review_note
        region {
          id
          name_json
        }
        organization_type {
          name_json
          id
        }
        name
        pobox
        phone_no
      }
    }
  }
`;

export const GET_ALL_ORGANIZATION = gql`
  query GetALLOrganization(
    $limit: Int = 10
    $offset: Int
    $is_renewed: Boolean = false
  ) {
    organizations(
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
      where: { is_renewed: { _eq: $is_renewed } }
    ) {
      assigned_organization_reviewers {
        id
      }
      business_licence_number
      created_at
      user_info {
        id
        email
        name
      }
      district {
        id
        name_json
      }
      email
      expatriates_count
      fax
      local_employees_count
      house_number
      id
      zone {
        id
        name_json
      }
      woreda {
        id
        name_json
      }
      tin_number
      subcity {
        id
        name_json
      }
      sector {
        id
        name_json
      }
      expatriate_work_permit {
        expatriate {
          first_name
          passport_number
          father_name
          gender {
            name
          }
          marital {
            name
          }
        }
        expatriate_work_permit_documents {
          id
          document_type {
            name
            id
          }
          review_note
          review_status
        }
      }
      organization_documents {
        files
        review_status
        review_note
      }
      review_status
      review_note
      region {
        id
        name_json
      }
      organization_type {
        name_json
        id
      }
      name
      pobox
      phone_no
    }
  }
`;

export const GET_ALL_ORGANIZATION_FILTER = gql`
  query filter_organization_list($searchInput: String) {
    organizations(
      order_by: { created_at: desc }
      where: {
        _or: [
          { tin_number: { _ilike: $searchInput } }
          { name: { _ilike: $searchInput } }
        ]
      } # limit: $limit # offset: $offset
    ) {
      assigned_organization_reviewers {
        id
      }
      business_licence_number
      created_at
      user_info {
        id
        email
        name
      }
      district {
        id
        name_json
      }
      email
      expatriates_count
      fax
      local_employees_count
      house_number
      id
      zone {
        id
        name_json
      }
      woreda {
        id
        name_json
      }
      tin_number
      subcity {
        id
        name_json
      }
      sector {
        id
        name_json
      }
      expatriate_work_permit {
        expatriate {
          first_name
          passport_number
          father_name
          gender {
            name
          }
          marital {
            name
          }
        }
        expatriate_work_permit_documents {
          id
          document_type {
            name
            id
          }
          review_note
          review_status
        }
      }
      organization_documents {
        files
        review_status
        review_note
      }
      review_status
      review_note
      region {
        id
        name_json
      }
      organization_type {
        name_json
        id
      }
      name
      pobox
      phone_no
    }
  }
`;

/**
 * @description get organizatioin information for organization side which has less information
 */
export const ORG_GET_ORGANIZATION_BYID = gql`
  query ORG_GET_ORGANIZATION_BYID($id: uuid = "") {
    organizations(where: { id: { _eq: $id } }) {
      document_status
      business_licence_number
      created_at
      user_info {
        id
        email
        name
      }
      district {
        id
        name_json
      }
      email
      expatriates_count
      fax
      exp_work_permits_json
      local_employees_count
      house_number
      id
      zone {
        id
        name_json
      }
      woreda {
        id
        name_json
      }
      tin_number
      subcity {
        id
        name_json
      }
      sector {
        id
        name_json
      }
      review_status
      review_note
      region {
        id
        name_json
      }
      organization_documents {
        created_at
        files
        id
        review_note
        review_status
      }
      organization_type {
        name_json
        id
      }
      name
      pobox
      phone_no
    }
  }
`;

/**
 * @description which has more information for admins including who is assigned and their status
 */
export const GET_ORGANIZATION_BYID = gql`
  query GetOrganizationByID($id: uuid = "") {
    organizations(where: { id: { _eq: $id } }) {
      assigned_organization_reviewers {
        id
        employee {
          user_info {
            name
            phone_number
            email
          }
        }
        status
      }
      document_status
      business_licence_number
      business_licence_issued_date
      business_licence_expiry_date
      is_renewed
      created_at
      user_info {
        id
        email
        phone_number
        name
      }
      district {
        id
        name_json
      }
      email
      expatriates_count
      fax
      exp_work_permits_json
      local_employees_count
      house_number
      id
      zone {
        id
        name_json
      }
      woreda {
        id
        name_json
      }
      tin_number
      subcity {
        id
        name_json
      }
      sector {
        id
        name_json
      }
      review_status
      review_note
      region {
        id
        name_json
      }
      organization_documents {
        created_at
        files
        id
        review_note
        review_status
      }
      organization_type {
        name_json
        id
      }
      name
      pobox
      phone_no
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query GetOrganization($eq: uuid = "") {
    organizations(where: { user_id: { _eq: $eq } }) {
      user_info {
        email
        id
        name
      }
      id
      name
      review_status
      review_note
      email
      exp_work_permits_json
      business_licence_number
      created_at
      created_by
      expatriates_count
      house_number
      id
      fax
      pobox
      local_employees_count
      # major_activity
      name
      organization_type {
        name
        id
      }
      region {
        name
        id
      }
      organization_documents {
        id
        files
        review_note
        review_status
      }

      sector {
        name
        id
      }
      phone_no
      tin_number
    }
  }
`;

export const ORGANIZATION_BY_ID = gql`
  query ORGANIZATION_BY_ID($OrgID: uuid = "") {
    organizations(where: { id: { _eq: $OrgID } }) {
      # activity_code
      email
      business_licence_number
      created_at
      created_by
      expatriates_count
      house_number
      id
      local_employees_count
      # major_activity
      name
      organization_type {
        name
      }
      region {
        name
      }
      sector {
        name
      }
      phone_no
      tin_number
      # town
      expatriate_work_permit {
        id
        emr_number
        expatriate {
          first_name
          father_name
          profession_title {
            id
            name_json
          }
        }
      }
      user_info {
        name
        phone_number
        email
      }
    }
  }
`;

export const GET_ORGANIZATION_RENEWAL = gql`
  query GET_ORGANIZATION_RENEWAL($orgID: uuid) {
    organization_renewal(where: { organization_id: { _eq: $orgID } }) {
      expiry_date
      organization_id
      review_note
      review_status
    }
  }
`;

export const APPLICATION_REQUIREMENTS = gql`
  query ApplicationRequirements {
    __typename # Placeholder value
    application_requirements {
      id
      title
      description
    }
  }
`;

export const WORK_PERMIT_REQUESTS = gql`
  query WorkPermitRequests {
    work_permit_requests {
      canceled
      allowance
      first_name
      father_name
      id
    }
  }
`;
// =================================================

export const GET_WORK_PERMITS = gql`
  query GetWorkPermits($organization_id: uuid) {
    work_permit_requests(
      where: { organization_id: { _eq: $organization_id } }
    ) {
      first_name
      father_name
      gender
      id
      salary
      allowance
      canceled
      entry_date
      employment_end_date
      employment_date
      nationality {
        id
        name
      }
      passport_expiry_date
      passport_issued_date
      passport_number
      phone_number
      visa_expiry_date
      verified
      visa_issued_date
      visa_number
      visa_type
    }
  }
`;

export const GET_ORGANIZATION_TYPES = gql`
  query organizationTypes {
    __typename
    organization_types {
      id
      name
    }
  }
`;

export const GET_REGIONS = gql`
  query regions {
    __typename
    regions {
      id
      name
    }
  }
`;

export const GET_SECTORS = gql`
  query sectors {
    __typename # Placeholder value
    sectors {
      id
      name
    }
  }
`;

export const GET_QUALIFICATION_LEVELS = gql`
  query GetQualificationLevels {
    qualification_levels {
      id
      name
    }
  }
`;

export const GET_QUALIFICATION_TYPES = gql`
  query GetQualificationTypes {
    qualification_types {
      id
      name_json
    }
  }
`;

export const GET_PROFESSIONAL_TITLES = gql`
  query GetProfessionalTitles {
    profession_titles {
      id
      name_json
    }
  }
`;

export const GET_SERVICE_TYPES = gql`
  query GetServiceTypes {
    service_types {
      id
      name_json
    }
  }
`;

export const GET_NATIONALITIES = gql`
  query GetNationalities {
    nationalities {
      id
      name
    }
  }
`;

export const BASE_DOCUMENT_TYPES = gql`
  query base_document_type {
    base_document_types {
      description_json
      id
      documents_type_category_id
      document_type_category {
        code
        id
      }
      work_permit_type_id
      name_json
      name
    }
  }
`;

export const GET_OCCUPATION_CATEGORIES = gql`
  query GetOccupationCategories {
    __typename # Placeholder value
    occupation_categories {
      id
      name
    }
  }
`;

// ==============================================BASE DATA===================================
export const BASE_ROLES = gql`
  query roles {
    base_roles {
      value
      description
      id
    }
  }
`;

export const BASE_DOCUMENT_CATAGORY_TYPES = gql`
  query base_catagory {
    base_document_type_categories {
      code
      id
      document_types {
        name
        id
        file_type
      }
    }
  }
`;
export const BASE_GENDER = gql`
  query gender {
    base_genders {
      name
      id
    }
  }
`;

export const BASE_SECTORS = gql`
  query base_sectors {
    base_sectors {
      id
      name
      name_json
    }
  }
`;

export const BASE_REGION = gql`
  query base_regions @cached {
    base_regions {
      id
      name
      name_json
    }
  }
`;

export const BASE_ZONE = gql`
  query base_zone($region: uuid = "") {
    base_zones(where: { region_id: { _eq: $region } }) {
      id
      name_json
    }
  }
`;

export const BASE_VISA_TYPES = gql`
  query base_visa_types @cached {
    base_visa_types {
      id
      name
    }
  }
`;

export const BASE_WAY_OF_ENTER = gql`
  query base_way_of_enter @cached {
    base_way_of_entries {
      id
      name
    }
  }
`;

export const BASE_WOREDA = gql`
  query base_woreda($subcity: uuid = "") {
    base_woredas(where: { subcity_id: { _eq: $subcity } }) {
      id
      name_json
    }
  }
`;

export const BASE_DISTRICT = gql`
  query base_district($zone: uuid = "") {
    base_districts(where: { zone_id: { _eq: $zone } }) {
      id
      name_json
    }
  }
`;

export const BASE_NATIONALITY = gql`
  query base_nationality @cached {
    base_nationalities {
      id
      name
      name_json
      flag
    }
  }
`;

export const BASE_QUALIFICATION_TYPES = gql`
  query base_qualification_types @cached {
    base_qualification_types {
      id
      name
      name_json
    }
  }
`;

export const BASE_QUALIFICATION_LEVEL = gql`
  query base_qualification_level @cached {
    base_qualification_levels {
      id
      name
      name_json
    }
  }
`;

export const BASE_MARTIAL_STATUS = gql`
  query base_martial_status @cached {
    base_marital_statuses {
      id
      name
    }
  }
`;

export const BASE_OCCUPATION_CATEGORIES = gql`
  query base_occupation_categories {
    base_occupation_categories {
      id
      name
      name_json
    }
  }
`;

export const BASE_PROFESSIONAL_TITLE = gql`
  query base_professional_title {
    base_profession_titles {
      id
      name_json
    }
  }
`;

export const BASE_ORGANIZATIOIN_TYPE = gql`
  query base_organization_types {
    base_organization_types {
      id
      name
      name_json
    }
  }
`;

export const BASE_SUBCITY = gql`
  query base_subcity($region: uuid = "") {
    base_subcities(where: { region_id: { _eq: $region } }) {
      id
      name_json
    }
  }
`;

export const GET_VISA_TYPE = gql`
  query base_visa_types($id: uuid = "") {
    base_visa_types(where: { id: { _eq: $id } }) {
      id
      name
    }
  }
`;

export const BASE_SERVICE_TYPE = gql`
  query base_service_types {
    base_service_types {
      id
      name_json
    }
  }
`;
// ==========================================================================================
export const GET_PERMIT_DOCUMENTS = gql`
  query GetExpatriateWorkPermitDocuments($permitID: uuid) {
    expatriate_work_permit_documents(
      where: { expatriate_work_permit_id: { _eq: $permitID } }
    ) {
      review_status
      files
      properties
      review_note
      title
      created_at
      reviewed_on
      updated_at
      created_by
      document_type_id
      expatriate_work_permit_id
      id
      reviewed_by
      updated_by
    }
  }
`;

// ($limit: Int = 10, $offset: Int = 0)
export const GET_EXPATRIATE_LIST = gql`
  query expatriates(
    $limit: Int = 10
    $offset: Int = 0
    $permitType: uuid = "WORK_PERMIT_TYPES.NEW"
  ) {
    expatriates(
      where: { expatriate_work_permits: { permit_type: { _eq: $permitType } } }
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
    ) {
      created_at
      date_of_birth
      education_backgrounds {
        attachments
        field_of_study
        id
        review_status
        review_note
        received_date
        qualification_type {
          id
          name_json
          name
        }
        qualification_level {
          id
          name
          name_json
        }
        institution_name
      }
      expatriate_work_permits {
        allowance
        reference_number
        assigned_workpermit_reviewers {
          employee {
            user_info {
              name
              phone_number
              allowed_roles {
                role_information {
                  id
                  value
                }
              }
            }
          }
          status
          created_at
        }
        work_permit_black_list {
          approved_by
          approved_on
          id
          requested_on
          request_note
          requested_by
        }
        created_at
        end_date
        created_by
        expatriate_counterparts {
          assigned_date
          created_by
          first_name
          last_name
          position
        }
        expatriate_work_permit_documents {
          files
          id
          created_at
          document_type {
            name_json
            id
          }
          properties
          review_status
          review_note
          title
        }
        start_date
        salary
        reviewed_note
        review_status
        occupation_category {
          name_json
          name
        }
        issued_date
        id
        expiry_date
        expatriate_work_permit_type {
          name
        }
        expatriate_work_places {
          district {
            name_json
          }
          house_number
          kebele
          zone {
            name_json
          }
          subcity {
            name_json
          }
          region {
            name_json
          }
          project_name
          phone_number
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
          email
          business_licence_number
          tin_number
          district {
            name_json
          }
          zone {
            name_json
          }
          woreda {
            name_json
          }
          subcity {
            name_json
          }
          user_info {
            name
            phone_number
          }
        }
      }
      father_name
      first_name
      grand_father_name
      gender {
        name
      }
      marital {
        name
      }
      id
      nationality {
        name
      }
      visa_type {
        id
        name
      }
      way_of_entry {
        name
      }
      skills
      profession_title {
        id
        name_json
      }
      id
      passport_number
      visa_type {
        id
      }
      visa_number
    }
  }
`;

//=============================Not Assigned Permits=========
/**
 * @description get filtered permit
 */
export const GET_NOT_ASSIGNED_FILTERED_PERMIT = gql`
  query filterPermitList(
    $limit: Int = 10
    $offset: Int = 0
    $permitType: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    expatriate_work_permits(
      order_by: { created_at: desc }
      where: {
        _and: [
          { permit_type: { _eq: $permitType } }
          { _not: { assigned_workpermit_reviewers: {} } }
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      allowance
      reference_number
      emr_number
      assigned_workpermit_reviewers {
        employee {
          user_info {
            name
            phone_number
            allowed_roles {
              role_information {
                id
                value
              }
            }
          }
        }
        status
        created_at
      }
      work_permit_black_list {
        approved_by
        approved_on
        id
        requested_on
        request_note
        requested_by
      }
      created_at
      end_date
      created_by
      expatriate_counterparts {
        assigned_date
        created_by
        first_name
        last_name
        position
      }
      expatriate_work_permit_documents {
        files
        id
        created_at
        document_type {
          name_json
          id
        }
        properties
        review_status
        review_note
        title
      }
      start_date
      salary
      reviewed_note
      review_status
      occupation_category {
        name_json
        name
        id
      }
      issued_date
      id
      expiry_date
      expatriate_work_permit_type {
        name
      }
      expatriate_work_places {
        district {
          name_json
        }
        house_number
        kebele
        zone {
          name_json
        }
        subcity {
          name_json
        }
        region {
          name_json
        }
        project_name
        phone_number
      }
      organization {
        id
        name
        sector {
          name_json
        }
        organization_type {
          name_json
        }
        email
        business_licence_number
        tin_number
        district {
          name_json
        }
        zone {
          name_json
        }
        woreda {
          name_json
        }
        subcity {
          name_json
        }
        user_info {
          name
          phone_number
        }
      }
      expatriate {
        father_name
        first_name
        grand_father_name
        gender {
          name
        }
        marital {
          name
        }
        id
        nationality {
          name
        }
        visa_type {
          id
          name
        }
        way_of_entry {
          name
        }
        skills
        profession_title {
          id
          name_json
        }
        id
        passport_number
        visa_type {
          id
        }
        visa_number
        created_at
        date_of_birth
        education_backgrounds {
          attachments
          field_of_study
          id
          review_status
          review_note
          received_date
          qualification_type {
            id
            name_json
            name
          }
          qualification_level {
            id
            name
            name_json
          }
          institution_name
        }
      }
    }
  }
`;
export const COUNT_NOT_ASSIGNED_PERMITS = gql`
  query countPermit(
    $permitType: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    expatriate_work_permits_aggregate(
      where: {
        _and: [
          { permit_type: { _eq: $permitType } }
          { _not: { assigned_workpermit_reviewers: {} } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
// ===========================================================
export const GET_WORK_PERMIT_FILTERED = gql`
  query filterPermitList(
    $limit: Int = 10
    $offset: Int = 0
    $permitType: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    expatriate_work_permits(
      order_by: { created_at: desc }
      where: { permit_type: { _eq: $permitType } }
      limit: $limit
      offset: $offset
    ) {
      allowance
      reference_number
      emr_number
      assigned_workpermit_reviewers {
        employee {
          user_info {
            name
            phone_number
            allowed_roles {
              role_information {
                id
                value
              }
            }
          }
        }
        status
        created_at
      }
      work_permit_black_list {
        approved_by
        approved_on
        id
        requested_on
        request_note
        requested_by
      }
      created_at
      end_date
      created_by
      expatriate_counterparts {
        assigned_date
        created_by
        first_name
        last_name
        position
      }
      expatriate_work_permit_documents {
        files
        id
        created_at
        document_type {
          name_json
          id
        }
        properties
        review_status
        review_note
        title
      }
      start_date
      salary
      reviewed_note
      review_status
      occupation_category {
        name_json
        name
        id
      }
      issued_date
      id
      expiry_date
      expatriate_work_permit_type {
        name
      }
      expatriate_work_places {
        district {
          name_json
        }
        house_number
        kebele
        zone {
          name_json
        }
        subcity {
          name_json
        }
        region {
          name_json
        }
        project_name
        phone_number
      }
      organization {
        id
        name
        sector {
          name_json
        }
        organization_type {
          name_json
        }
        email
        business_licence_number
        tin_number
        district {
          name_json
        }
        zone {
          name_json
        }
        woreda {
          name_json
        }
        subcity {
          name_json
        }
        user_info {
          name
          phone_number
        }
      }
      expatriate {
        father_name
        first_name
        grand_father_name
        gender {
          name
        }
        marital {
          name
        }
        id
        nationality {
          name
        }
        visa_type {
          id
          name
        }
        way_of_entry {
          name
        }
        skills
        profession_title {
          id
          name_json
        }
        id
        passport_number
        visa_type {
          id
        }
        visa_number
        created_at
        date_of_birth
        education_backgrounds {
          attachments
          field_of_study
          id
          review_status
          review_note
          received_date
          qualification_type {
            id
            name_json
            name
          }
          qualification_level {
            id
            name
            name_json
          }
          institution_name
        }
      }
    }
  }
`;
/**
 * @description get filtered permit
 */
export const GET_FILTERED_RENEWAL_PERMIT = gql`
  query filterPermitList(
    $limit: Int = 10
    $offset: Int = 0
    $permitType: uuid = "f4f0b403-3c62-4890-8700-8c25ff87c05d"
  ) {
    expatriate_work_permits(
      order_by: { created_at: desc }
      where: { _and: [{ permit_type: { _eq: $permitType } }] }
      limit: $limit
      offset: $offset
    ) {
      allowance
      reference_number
      emr_number
      assigned_workpermit_reviewers {
        employee {
          user_info {
            name
            phone_number
            allowed_roles {
              role_information {
                id
                value
              }
            }
          }
        }
        status
        created_at
      }
      work_permit_black_list {
        approved_by
        approved_on
        id
        requested_on
        request_note
        requested_by
      }
      created_at
      end_date
      created_by
      expatriate_counterparts {
        assigned_date
        created_by
        first_name
        last_name
        position
      }
      expatriate_work_permit_documents {
        files
        id
        created_at
        document_type {
          name_json
          id
        }
        properties
        review_status
        review_note
        title
      }
      start_date
      salary
      reviewed_note
      review_status
      occupation_category {
        name_json
        name
        id
      }
      issued_date
      id
      expiry_date
      expatriate_work_permit_type {
        name
      }
      expatriate_work_places {
        district {
          name_json
        }
        house_number
        kebele
        zone {
          name_json
        }
        subcity {
          name_json
        }
        region {
          name_json
        }
        project_name
        phone_number
      }
      organization {
        id
        name
        sector {
          name_json
        }
        organization_type {
          name_json
        }
        email
        business_licence_number
        tin_number
        district {
          name_json
        }
        zone {
          name_json
        }
        woreda {
          name_json
        }
        subcity {
          name_json
        }
        user_info {
          name
          phone_number
        }
      }
      expatriate {
        father_name
        first_name
        grand_father_name
        gender {
          name
        }
        marital {
          name
        }
        id
        nationality {
          name
        }
        visa_type {
          id
          name
        }
        way_of_entry {
          name
        }
        skills
        profession_title {
          id
          name_json
        }
        id
        passport_number
        visa_type {
          id
        }
        visa_number
        created_at
        date_of_birth
        education_backgrounds {
          attachments
          field_of_study
          id
          review_status
          review_note
          received_date
          qualification_type {
            id
            name_json
            name
          }
          qualification_level {
            id
            name
            name_json
          }
          institution_name
        }
      }
    }
  }
`;

export const GET_FILTERED_PERMIT = gql`
  query filterPermitList(
    $limit: Int = 10
    $offset: Int = 0
    $permitType: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    expatriate_work_permits(
      order_by: { created_at: desc }
      where: {
        _and: [
          { permit_type: { _eq: $permitType } }
          { assigned_workpermit_reviewers: {} }
        ]
      }
      limit: $limit
      offset: $offset
    ) {
      allowance
      reference_number
      emr_number
      assigned_workpermit_reviewers {
        employee {
          user_info {
            name
            phone_number
            allowed_roles {
              role_information {
                id
                value
              }
            }
          }
        }
        status
        created_at
      }
      work_permit_black_list {
        approved_by
        approved_on
        id
        requested_on
        request_note
        requested_by
      }
      created_at
      updated_at
      end_date
      created_by
      expatriate_counterparts {
        assigned_date
        created_by
        first_name
        last_name
        position
      }
      expatriate_work_permit_documents {
        files
        id
        created_at
        document_type {
          name_json
          id
        }
        properties
        review_status
        review_note
        title
      }
      start_date
      salary
      reviewed_note
      review_status
      occupation_category {
        name_json
        name
        id
      }
      issued_date
      id
      expiry_date
      expatriate_work_permit_type {
        name
      }
      expatriate_work_places {
        district {
          name_json
        }
        house_number
        kebele
        zone {
          name_json
        }
        subcity {
          name_json
        }
        region {
          name_json
        }
        project_name
        phone_number
      }
      organization {
        id
        name
        sector {
          name_json
        }
        organization_type {
          name_json
        }
        email
        business_licence_number
        tin_number
        district {
          name_json
        }
        zone {
          name_json
        }
        woreda {
          name_json
        }
        subcity {
          name_json
        }
        user_info {
          name
          phone_number
        }
      }
      expatriate {
        father_name
        first_name
        grand_father_name
        gender {
          name
        }
        marital {
          name
        }
        id
        nationality {
          name
        }
        visa_type {
          id
          name
        }
        way_of_entry {
          name
        }
        skills
        profession_title {
          id
          name_json
        }
        id
        passport_number
        visa_type {
          id
        }
        visa_number
        created_at
        date_of_birth
        education_backgrounds {
          attachments
          field_of_study
          id
          review_status
          review_note
          received_date
          qualification_type {
            id
            name_json
            name
          }
          qualification_level {
            id
            name
            name_json
          }
          institution_name
        }
      }
    }
  }
`;
export const COUNT_ASSIGNED_PERMITS = gql`
  query countPermit(
    $permitType: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    expatriate_work_permits_aggregate(
      where: {
        _and: [
          { permit_type: { _eq: $permitType } }
          { assigned_workpermit_reviewers: {} }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_FILTERED_PERMIT_EMR = gql`
  query filterVerifiedPermits(
    $limit: Int = 10
    $offset: Int = 0
    $documentChecker: Boolean = true
    $permitType: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    expatriate_work_permits(
      order_by: { created_at: desc }
      where: {
        permit_type: { _eq: $permitType }
        document_checker_status: { _eq: $documentChecker }
      }
      limit: $limit
      offset: $offset
    ) {
      allowance
      reference_number
      emr_number
      assigned_workpermit_reviewers {
        employee {
          user_info {
            name
            phone_number
            allowed_roles {
              role_information {
                id
                value
              }
            }
          }
        }
        status
        created_at
      }
      work_permit_black_list {
        approved_by
        approved_on
        id
        requested_on
        request_note
        requested_by
      }
      created_at
      end_date
      created_by
      expatriate_counterparts {
        assigned_date
        created_by
        first_name
        last_name
        position
      }
      expatriate_work_permit_documents {
        files
        id
        created_at
        document_type {
          name_json
          id
        }
        properties
        review_status
        review_note
        title
      }
      start_date
      salary
      reviewed_note
      review_status
      occupation_category {
        name_json
        name
        id
      }
      issued_date
      id
      expiry_date
      expatriate_work_permit_type {
        name
      }
      expatriate_work_places {
        district {
          name_json
        }
        house_number
        kebele
        zone {
          name_json
        }
        subcity {
          name_json
        }
        region {
          name_json
        }
        project_name
        phone_number
      }
      organization {
        id
        name
        sector {
          name_json
        }
        organization_type {
          name_json
        }
        email
        business_licence_number
        tin_number
        district {
          name_json
        }
        zone {
          name_json
        }
        woreda {
          name_json
        }
        subcity {
          name_json
        }
        user_info {
          name
          phone_number
        }
      }
      expatriate {
        father_name
        first_name
        grand_father_name
        gender {
          name
        }
        marital {
          name
        }
        id
        nationality {
          name
        }
        visa_type {
          id
          name
        }
        way_of_entry {
          name
        }
        skills
        profession_title {
          id
          name_json
        }
        id
        passport_number
        visa_type {
          id
        }
        visa_number
        created_at
        date_of_birth
        education_backgrounds {
          attachments
          field_of_study
          id
          review_status
          review_note
          received_date
          qualification_type {
            id
            name_json
            name
          }
          qualification_level {
            id
            name
            name_json
          }
          institution_name
        }
      }
    }
  }
`;
export const GET_FILTERED_PERMIT_BYID = gql`
  query filterpermitList(
    $permitID: uuid = ""
    $permitType: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    expatriate_work_permits(
      where: { id: { _eq: $permitID }, permit_type: { _eq: $permitType } }
    ) {
      allowance
      reference_number
      emr_number
      document_checker_status
      reviewed_by_info {
        name
        allowed_roles {
          role_information {
            value
          }
        }
      }
      assigned_workpermit_reviewers {
        employee {
          user_info {
            name
            phone_number
            allowed_roles {
              role_information {
                id
                value
              }
            }
          }
        }
        status
        created_at
      }
      work_permit_black_list {
        approved_by
        approved_on
        id
        requested_on
        request_note
        requested_by
      }
      created_at
      end_date
      created_by
      expatriate_counterparts {
        assigned_date
        created_by
        first_name
        last_name
        position
      }
      expatriate_work_permit_documents {
        files
        id
        created_at
        reviewed_by_info {
          name
          allowed_roles {
            role_information {
              value
              __typename
            }
            __typename
          }
          __typename
        }
        document_type {
          name_json
          id
        }
        properties
        review_status
        review_note
        title
      }
      start_date
      salary
      reviewed_note
      review_status
      occupation_category {
        name_json
        name
        id
      }
      issued_date
      id
      expiry_date
      expatriate_work_permit_type {
        name
      }
      expatriate_work_places {
        district {
          name_json
        }
        house_number
        kebele
        zone {
          name_json
        }
        subcity {
          name_json
        }
        region {
          name_json
        }
        project_name
        phone_number
      }
      organization {
        id
        name
        sector {
          name_json
        }
        organization_type {
          name_json
        }
        email
        business_licence_number
        tin_number
        district {
          name_json
        }
        zone {
          name_json
        }
        woreda {
          name_json
        }
        subcity {
          name_json
        }
        user_info {
          name
          phone_number
        }
      }
      expatriate {
        father_name
        first_name
        grand_father_name
        gender {
          name
        }
        marital {
          name
        }
        id
        nationality {
          name
        }
        visa_type {
          id
          name
        }
        way_of_entry {
          name
        }
        skills
        profession_title {
          id
          name_json
        }
        id
        passport_number
        visa_type {
          id
        }
        visa_number
        created_at
        date_of_birth
        education_backgrounds {
          attachments
          field_of_study
          id
          review_status
          review_note
          received_date
          qualification_type {
            id
            name_json
            name
          }
          qualification_level {
            id
            name
            name_json
          }
          institution_name
        }
      }
    }
  }
`;

export const GET_EXPATRIATE_BYID = gql`
  query expatriates($id: uuid = "") {
    expatriates(where: { id: { _eq: $id } }) {
      created_at
      date_of_birth
      education_backgrounds {
        attachments
        field_of_study
        id
        review_status
        review_note
        received_date
        qualification_type {
          id
          name_json
          name
        }
        qualification_level {
          id
          name
          name_json
        }
        institution_name
      }
      expatriate_work_permits {
        allowance
        document_checker_status
        expatriate_work_permit_type {
          name
        }
        assigned_workpermit_reviewers {
          employee {
            user_info {
              id
              name
              phone_number
              allowed_roles {
                role_information {
                  id
                  value
                }
              }
            }
          }
          status
          created_at
        }
        created_at
        end_date
        created_by
        expatriate_counterparts {
          assigned_date
          created_by
          first_name
          last_name
          position
        }
        # assigned_workpermit_reviewers {
        #   employee {
        #     user_info {
        #       name
        #       email
        #       allowed_roles {
        #         role_information
        #       }
        #     }
        #     active_status
        #   }
        # }
        expatriate_work_permit_documents {
          files
          id
          created_at
          document_type {
            name_json
            id
          }
          properties
          review_status
          review_note
          title
          reviewed_by_info {
            name
          }
        }
        start_date
        salary
        reviewed_note
        review_status
        occupation_category {
          name_json
          name
        }
        issued_date
        id
        expiry_date
        expatriate_work_permit_type {
          name
        }
        expatriate_work_places {
          district {
            name_json
          }
          house_number
          kebele
          zone {
            name_json
          }
          subcity {
            name_json
          }
          region {
            name_json
          }
          project_name
          phone_number
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
          email
          business_licence_number
          tin_number
          phone_no
          district {
            name_json
          }
          zone {
            name_json
          }
          woreda {
            name_json
          }
          subcity {
            name_json
          }
          user_info {
            name
            phone_number
          }
        }
      }
      father_name
      first_name
      grand_father_name
      gender {
        name
      }
      marital {
        name
        id
      }
      id
      nationality {
        name
      }
      visa_type {
        id
        name
      }
      way_of_entry {
        name
      }
      skills
      profession_title {
        id
        name_json
      }
      passport_number
      visa_type
      visa_number
    }
  }
`;

export const GET_EXPATRIATE_BYID_ORG = gql`
  query expatriates($id: uuid = "") {
    expatriates(where: { id: { _eq: $id } }) {
      created_at
      date_of_birth
      education_backgrounds {
        attachments
        field_of_study
        id
        review_status
        review_note
        received_date
        qualification_type {
          id
          name_json
          name
        }
        qualification_level {
          id
          name
          name_json
        }
        institution_name
      }
      expatriate_work_permits {
        allowance
        created_at
        end_date
        created_by
        expatriate_counterparts {
          assigned_date
          created_by
          first_name
          last_name
          position
        }
        expatriate_work_permit_documents {
          files
          id
          document_type {
            name_json
            id
          }
          properties
          review_status
          review_note
          title
        }
        start_date
        salary
        reviewed_note
        review_status
        occupation_category {
          name_json
          name
        }
        issued_date
        id
        expiry_date
        expatriate_work_permit_type {
          name
        }
        expatriate_work_places {
          district {
            name_json
          }
          house_number
          kebele
          zone {
            name_json
          }
          subcity {
            name_json
          }
          region {
            name_json
          }
          project_name
          phone_number
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
          email
          business_licence_number
          tin_number
          phone_no
          district {
            name_json
          }
          zone {
            name_json
          }
          woreda {
            name_json
          }
          subcity {
            name_json
          }
          user_info {
            name
            phone_number
          }
        }
      }
      father_name
      first_name
      grand_father_name
      gender {
        name
      }
      marital {
        name
        id
      }
      id
      nationality {
        name
      }
      visa_type {
        id
        name
      }
      way_of_entry {
        name
      }
      skills
      profession_title {
        id
        name_json
      }
      passport_number
      visa_type
      visa_number
    }
  }
`;

export const GET_WORK_PERMIT_BYID = gql`
  query expatriate_work_permits($id: uuid = " ") {
    expatriate_work_permits(where: { id: { _eq: $id } }) {
      emr_number
      reference_number
      allowance
      created_at
      end_date
      created_by
      document_checker_status
      assigned_workpermit_reviewers {
        created_at
        employee {
          user_info {
            id
            name
            allowed_roles {
              role_information {
                value
              }
            }
          }
        }
      }
      expatriate_counterparts {
        assigned_date
        created_by
        first_name
        last_name
        position
      }
      expatriate_work_permit_documents {
        files
        id
        created_at
        document_type {
          name_json
          id
        }
        properties
        review_status
        review_note
        title
        reviewed_by_info {
          name
        }
      }
      start_date
      salary
      reviewed_note
      review_status
      occupation_category {
        name_json
        name
      }
      issued_date
      id
      expiry_date
      passport_expiry_date
      passport_issued_date
      visa_expiry_date
      visa_issued_date
      expatriate_work_permit_type {
        name
      }
      expatriate_work_places {
        district {
          name_json
        }
        house_number
        kebele
        zone {
          name_json
        }
        subcity {
          name_json
        }
        region {
          name_json
        }
        project_name
        phone_number
      }
      organization {
        id
        name
        sector {
          name_json
        }
        organization_type {
          name_json
        }
        email
        business_licence_number
        tin_number
        phone_no
        district {
          name_json
        }
        zone {
          name_json
        }
        woreda {
          name_json
        }
        subcity {
          name_json
        }
        user_info {
          name
          phone_number
        }
        review_status
      }
      issued_date
      expiry_date
      expatriate {
        created_at
        date_of_birth
        father_name
        first_name
        grand_father_name
        gender {
          name
          id
        }
        marital {
          name
          id
        }
        id
        nationality {
          name
          id
        }
        visa_type {
          id
          name
        }
        way_of_entry {
          name
          id
        }
        skills
        profession_title {
          id
          name_json
        }
        passport_number
        visa_type
        visa_number
        education_backgrounds {
          attachments
          field_of_study
          id
          review_status
          review_note
          received_date
          qualification_type {
            id
            name_json
            name
          }
          qualification_level {
            id
            name
            name_json
          }
          institution_name
        }
      }
      permit_type
    }
  }
`;

export const GET_WORK_PERMIT_BYID_FOR_ORG = gql`
  query GET_WORK_PERMIT_BYID_FOR_ORG($id: uuid = " ") {
    expatriate_work_permits(where: { id: { _eq: $id } }) {
      emr_number
      reference_number
      allowance
      created_at
      end_date
      created_by
      document_checker_status
      expatriate_counterparts {
        assigned_date
        created_by
        first_name
        last_name
        position
      }
      expatriate_work_permit_documents {
        files
        id
        created_at
        document_type {
          name_json
          id
        }
        properties
        review_status
        review_note
        title
        reviewed_by_info {
          name
        }
      }
      start_date
      salary
      reviewed_note
      review_status
      occupation_category {
        name_json
        name
      }
      issued_date
      id
      expiry_date
      expatriate_work_permit_type {
        name
      }
      expatriate_work_places {
        district {
          name_json
        }
        house_number
        kebele
        zone {
          name_json
        }
        subcity {
          name_json
        }
        region {
          name_json
        }
        project_name
        phone_number
      }
      organization {
        id
        name
        sector {
          name_json
        }
        organization_type {
          name_json
        }
        email
        business_licence_number
        tin_number
        phone_no
        district {
          name_json
        }
        zone {
          name_json
        }
        woreda {
          name_json
        }
        subcity {
          name_json
        }
        user_info {
          name
          phone_number
        }
        review_status
      }
      issued_date
      expiry_date
      passport_issued_date
      passport_expiry_date
      visa_issued_date
      visa_expiry_date
      expatriate {
        created_at
        date_of_birth
        father_name
        first_name
        grand_father_name
        gender {
          name
          id
        }
        marital {
          name
          id
        }
        id
        nationality {
          name
          id
        }
        visa_type {
          id
          name
        }
        way_of_entry {
          name
          id
        }
        skills
        profession_title {
          id
          name_json
        }
        passport_number
        visa_type
        visa_number
        education_backgrounds {
          attachments
          field_of_study
          id
          review_status
          review_note
          received_date
          qualification_type {
            id
            name_json
            name
          }
          qualification_level {
            id
            name
            name_json
          }
          institution_name
        }
      }
      permit_type
    }
  }
`;

export const GET_ORGANIZATION_PERMITS = gql`
  query organizationWorkPermit($orgID: uuid = "") {
    expatriate_work_permits(where: { organization_id: { _eq: $orgID } }) {
      id
      allowance
      document_status
      emr_number
      created_at
      updated_at
      expatriate_work_permit_type {
        name
        id
      }
      expatriate {
        id
        first_name
        marital {
          name
          id
        }
        father_name
        grand_father_name
        date_of_birth
        skills
        education_backgrounds {
          field_of_study
          review_status
          review_note
          received_date
          qualification_level {
            name
          }
        }
        way_of_entry {
          name
        }
        nationality {
          name
        }
      }
      organization {
        name
        organization_documents {
          id
          files
          review_note
          review_status
        }
      }
      review_status
      reviewed_note
    }
  }
`;

export const ORGANIZATION_DASHBOARD_DATA = gql`
  query organization_dashboard_data($orgID: uuid = "") {
    expatriate_work_permits_aggregate(
      where: { organization_id: { _eq: $orgID } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const USER_INFO = gql`
  query user_info($id: uuid = "") {
    public_users(where: { id: { _eq: $id } }) {
      email
      name
    }
  }
`;

export const GET_ALL_ASSIGNED_WORK_PERMITS = gql`
  query get_all_assigned_work_permit(
    $employeeID: uuid = ""
    $limit: Int = 10
    $offset: Int = 0
    $permit: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    assigned_workpermit_reviewers(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        employee_id: { _eq: $employeeID }
        expatriate_work_permit: {
          permit_type: { _eq: $permit }
          document_checker_status: { _is_null: true }
        }
      }
    ) {
      id
      expatriate_work_permit {
        id
        allowance
        created_at
        updated_at
        document_status
        reference_number
        document_checker_status
        expatriate_work_permit_documents {
          review_status
          properties
          title
          id
          review_note
          files
          document_type {
            name
          }
        }
        expatriate {
          id
          first_name
          marital {
            name
            id
          }
          father_name
          grand_father_name
          date_of_birth
          skills
          gender {
            name
            id
          }
          education_backgrounds {
            field_of_study
            review_status
            review_note
            received_date
            qualification_level {
              name
              id
            }
          }
          way_of_entry {
            name
          }
          nationality {
            name
          }
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
        }
        expatriate_work_permit_documents {
          document_type {
            id
          }
          id
          created_at
          files
          title
          review_status
          review_note
          properties
          document_type {
            name
          }
        }
        review_status
        reviewed_note
      }
    }
  }
`;
export const DOCUMENT_ALL_APPROVED_WORK_PERMITS = gql`
  query DOCUMENT_ALL_APPROVED_WORK_PERMITS(
    $employeeID: uuid = ""
    $limit: Int = 10
    $offset: Int = 0
    $checkerStatus: Boolean = false
    $permit: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    assigned_workpermit_reviewers(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        employee_id: { _eq: $employeeID }
        expatriate_work_permit: {
          permit_type: { _eq: $permit }
          document_checker_status: { _eq: $checkerStatus }
        }
      }
    ) {
      id
      expatriate_work_permit {
        id
        allowance
        created_at
        updated_at
        document_status
        reference_number
        document_checker_status
        expatriate_work_permit_documents {
          review_status
          properties
          title
          id
          review_note
          files
          document_type {
            name
          }
        }
        expatriate {
          id
          first_name
          marital {
            name
            id
          }
          father_name
          grand_father_name
          date_of_birth
          skills
          gender {
            name
            id
          }
          education_backgrounds {
            field_of_study
            review_status
            review_note
            received_date
            qualification_level {
              name
              id
            }
          }
          way_of_entry {
            name
          }
          nationality {
            name
          }
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
        }
        expatriate_work_permit_documents {
          document_type {
            id
          }
          id
          created_at
          files
          title
          review_status
          review_note
          properties
          document_type {
            name
          }
        }
        review_status
        reviewed_note
      }
    }
  }
`;

export const GET_ALL_ASSIGNED_WORK_PERMIT = gql`
  query get_assigned_work_permit {
    assigned_workpermit_reviewers {
      id
      expatriate_work_permit {
        id
        allowance
        created_at
        updated_at
        reference_number
        expatriate {
          id
          first_name
          marital {
            name
            id
          }
          father_name
          grand_father_name
          date_of_birth
          skills
          gender {
            name
            id
          }
          education_backgrounds {
            field_of_study
            review_status
            review_note
            received_date
            qualification_level {
              name
              id
            }
          }
          way_of_entry {
            name
          }
          nationality {
            name
          }
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
        }
        expatriate_work_permit_documents {
          document_type {
            id
          }
          id
          created_at
          files
          title
          review_status
          review_note
          properties
          document_type {
            name
          }
        }
        review_status
        reviewed_note
      }
    }
  }
`;

export const VERIFIED_WORK_PERMITS = gql`
  query verifiedPermits($status: Boolean = true) {
    expatriate_work_permits(where: { review_status: { _eq: $status } }) {
      id
      allowance
      created_at
      end_date
      start_date
      salary
      is_blacklisted
      expatriate {
        id
        first_name
        marital {
          name
        }
        expatriate_work_permits {
          review_status
          emr_number
        }
        father_name
        grand_father_name
        date_of_birth
        skills
        gender {
          name
        }
        education_backgrounds {
          field_of_study
          review_status
          review_note
          received_date
          qualification_level {
            name
          }
        }
        way_of_entry {
          name
        }
        nationality {
          name
        }
      }
      review_status
      reviewed_note
      organization {
        id
        name
        sector {
          name_json
        }
        organization_type {
          name_json
        }
      }
      expatriate_work_permit_documents {
        document_type {
          id
        }
        id
        created_at
        files
        title
        review_status
        review_note
        properties
        document_type {
          name
        }
      }
    }
  }
`;

export const SEARCH_ORGANIZATION_EMR = gql`
  query search_organization_emr($orgID: uuid = "", $emrID: String = "") {
    expatriate_work_permits(
      where: { organization_id: { _eq: $orgID }, emr_number: { _eq: $emrID } }
    ) {
      id
      allowance
      created_at
      end_date
      start_date
      salary
      is_blacklisted
      expatriate {
        id
        first_name
        passport_number
        visa_number
        visa_type {
          name
        }
        marital {
          id
          name
        }
        father_name
        grand_father_name
        date_of_birth
        skills
        gender {
          name
          id
        }
        education_backgrounds {
          field_of_study
          review_status
          review_note
          received_date
          qualification_level {
            name
          }
        }
        way_of_entry {
          name
          id
        }
        nationality {
          name
        }
      }
      review_status
      reviewed_note
      organization {
        id
        name
        sector {
          name_json
        }
        organization_type {
          name_json
        }
      }
      expatriate_work_permit_documents {
        document_type {
          id
        }
        id
        created_at
        files
        title
        review_status
        review_note
        properties
        document_type {
          name
        }
      }
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query employees {
    employees(order_by: { created_at: desc }) {
      active_status
      labor_id
      assigned_workpermit_reviewers_aggregate {
        aggregate {
          count
        }
      }
      assigned_organization_reviewers_aggregate {
        aggregate {
          count
        }
      }
      user_info {
        id
        email
        name
        phone_number
        allowed_roles {
          role_information {
            id
            value
          }
        }
      }
    }
  }
`;

export const SEARCH_PREV_ORGANIZATION = gql`
  query search_pervios_blacklist_organization($name: String = "") {
    prev_organization_black_lists(where: { name: { _regex: $name } }) {
      id
      name
      status
      note
    }
  }
`;

// DEK MANAGER
export const TOTAL_EXPT = gql`
  query total_expt {
    expatriate_work_permits_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const TOTAL_EXPT_FILTER = gql`
  query total_expt_filter($type: uuid = "") {
    expatriate_work_permits_aggregate(where: { permit_type: { _eq: $type } }) {
      aggregate {
        count
      }
    }
  }
`;

export const BASE_ORG_DOCUMENT_TYPES = gql`
  query GetOrgDocumentTypes {
    base_organization_document_types {
      name_json
      name
      id
      minimum_document_count
      max_document_count
      is_required
    }
  }
`;

// ============================================ASSIGNED===========================
export const TOTAL_ASSIGNED_WORK_PERMIT = gql`
  query total_assigned_work_permit(
    $employeeID: uuid = ""
    $permit: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    assigned_workpermit_reviewers_aggregate(
      where: {
        employee_id: { _eq: $employeeID }
        expatriate_work_permit: {
          permit_type: { _eq: $permit }
          document_checker_status: { _is_null: true }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const TOTAL_ASSIGNED_WORK_PERMIT_ALL = gql`
  query total_assigned_work_permit(
    $permit: uuid = "f4f0b403-3c62-4890-8700-8c25ff87c05d"
  ) {
    expatriate_work_permits_aggregate(
      where: {
        permit_type: { _eq: $permit }
        document_checker_status: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const TOTAL_ASSIGNED_WORK_PERMIT_BY_STATUS = gql`
  query total_assigned_work_permit(
    $employeeID: uuid = ""
    $checkerStatus: Boolean = false
    $permit: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    assigned_workpermit_reviewers_aggregate(
      where: {
        employee_id: { _eq: $employeeID }
        expatriate_work_permit: {
          permit_type: { _eq: $permit }
          document_checker_status: { _eq: $checkerStatus }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ASSIGNED_WORK_PERMIT = gql`
  query get_assigned_work_permit(
    $id: uuid = ""
    $limit: Int = 10
    $offset: Int = 0
  ) {
    assigned_workpermit_reviewers(
      limit: $limit
      offset: $offset
      where: { employee_id: { _eq: $id } }
    ) {
      id
      expatriate_work_permit {
        id
        allowance
        created_at
        document_status
        reference_number
        expatriate {
          id
          first_name
          marital {
            name
            id
          }
          father_name
          grand_father_name
          date_of_birth
          skills
          gender {
            name
            id
          }
          education_backgrounds {
            field_of_study
            review_status
            review_note
            received_date
            qualification_level {
              name
              id
            }
          }
          way_of_entry {
            name
          }
          nationality {
            name
          }
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
        }
        expatriate_work_permit_documents {
          document_type {
            id
          }
          id
          created_at
          files
          title
          review_status
          review_note
          properties
          document_type {
            name
          }
        }
        review_status
        reviewed_note
      }
    }
  }
`;

export const TOTAL_EXPAT_COUNT = gql`
  query total_expat {
    expatriates_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const TOTAL_PERMIT_EXPAT_COUNT = gql`
  query total_expat($permitType: uuid) {
    expatriate_work_permits_aggregate(
      where: {
        _and: [
          { permit_type: { _eq: $permitType } }
          { assigned_workpermit_reviewers: {} }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const APPROVED_EXPATRATES_PERMITS = gql`
  query verifyedWork_permit(
    $limit: Int = 10
    $offset: Int = 0
    $permit: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    expatriate_work_permits(
      # order_by: { emr_number: desc }
      limit: $limit
      offset: $offset
      where: {
        _and: [
          {document_checker_status: { _eq: true }}
          {emr_number: {_neq: "EMR126672"}}
        ]
        permit_type: { _eq: $permit }
      }
    ) {
      id
      emr_number
      immigration_letter
      reference_number
      reviewed_note
      document_status
      assigned_workpermit_reviewers {
        created_at
        employee {
          user_info {
            name
          }
        }
      }
      allowance
      created_by
      updated_by
      work_permit_status
      start_date
      salary
      reviewed_note
      reviewed_by_info {
        name
      }
      review_status
      expatriate_work_permit_type {
        name
      }
      expatriate {
        first_name
        father_name
        id
        created_at
      }
      organization {
        name
        tin_number
        email
        business_licence_number
        organization_type {
          name
        }
      }
      occupation_category {
        name
      }
      issued_date
      expiry_status
      expatriate_work_permit_documents {
        document_type {
          id
          __typename
        }
        id
        created_at
        files
        title
        review_status
        review_note
        properties
        document_type {
          name
          __typename
        }
        __typename
      }
    }
  }
`;

export const APPROVED_EXPATIATES_PERMITS_RENEWAL = gql`
  query verifiedRenewalWork_permit(
    $limit: Int = 10
    $offset: Int = 0
    $permit: uuid = "f4f0b403-3c62-4890-8700-8c25ff87c05d"
  ) {
    expatriate_work_permits(
      order_by: { emr_number: desc }
      limit: $limit
      offset: $offset
      where: {
        permit_type: { _eq: $permit }
        document_checker_status: { _eq: true }
      }
    ) {
      id
      emr_number
      reference_number
      reviewed_note
      document_status
      assigned_workpermit_reviewers {
        created_at
        employee {
          user_info {
            name
          }
        }
      }
      allowance
      created_by
      updated_by
      work_permit_status
      start_date
      salary
      reviewed_note
      reviewed_by_info {
        name
      }
      review_status
      expatriate_work_permit_type {
        name
      }
      expatriate {
        first_name
        father_name
        id
        created_at
      }
      organization {
        name
        tin_number
        email
        business_licence_number
        organization_type {
          name
        }
      }
      occupation_category {
        name
      }
      issued_date
      expiry_status
      expatriate_work_permit_documents {
        document_type {
          id
          __typename
        }
        id
        created_at
        files
        title
        review_status
        review_note
        properties
        document_type {
          name
          __typename
        }
        __typename
      }
    }
  }
`;

export const APPROVED_EXPATIATES_PERMITS_RENEWAL_FILTER = gql`
  query APPROVED_EXPATIATES_PERMITS_RENEWAL_FILTER(
    $limit: Int = 10
    $offset: Int = 0
    $emr: String
    $permit: uuid = "f4f0b403-3c62-4890-8700-8c25ff87c05d"
  ) {
    expatriate_work_permits(
      order_by: { emr_number: desc }
      limit: $limit
      offset: $offset
      where: {
        permit_type: { _eq: $permit }
        document_checker_status: { _eq: true }
        emr_number: { _ilike: $emr }
      }
    ) {
      id
      emr_number
      reference_number
      reviewed_note
      document_status
      assigned_workpermit_reviewers {
        created_at
        employee {
          user_info {
            name
          }
        }
      }
      allowance
      created_by
      updated_by
      work_permit_status
      start_date
      salary
      reviewed_note
      reviewed_by_info {
        name
      }
      review_status
      expatriate_work_permit_type {
        name
      }
      expatriate {
        first_name
        father_name
        id
        created_at
      }
      organization {
        name
        tin_number
        email
        business_licence_number
        organization_type {
          name
        }
      }
      occupation_category {
        name
      }
      issued_date
      expiry_status
      expatriate_work_permit_documents {
        document_type {
          id
          __typename
        }
        id
        created_at
        files
        title
        review_status
        review_note
        properties
        document_type {
          name
          __typename
        }
        __typename
      }
    }
  }
`;

export const SEARCH_EXPATIATES_PERMITS_RENEWAL_FILTER_DOCUMENT_CHECKER = gql`
  query SEARCH_EXPATIATES_PERMITS_RENEWAL_FILTER_DOCUMENT_CHECKER(
    $employeeID: uuid = ""
    $limit: Int = 10
    $offset: Int = 0
    $searchVal: String
    $permit: uuid = "f4f0b403-3c62-4890-8700-8c25ff87c05d"
  ) {
    assigned_workpermit_reviewers(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        employee_id: { _eq: $employeeID }
        expatriate_work_permit: { permit_type: { _eq: $permit } }
        _or: [
          {
            expatriate_work_permit: {
              expatriate: { first_name: { _iregex: $searchVal } }
            }
          }
          {
            expatriate_work_permit: {
              expatriate: { passport_number: { _ilike: $searchVal } }
            }
          }
          {
            expatriate_work_permit: {
              organization: { tin_number: { _ilike: $searchVal } }
            }
          }
        ]
      }
    ) {
      id
      expatriate_work_permit {
        id
        allowance
        created_at
        document_status
        reference_number
        document_checker_status
        expatriate_work_permit_documents {
          review_status
          properties
          title
          id
          review_note
          files
          document_type {
            name
          }
        }
        expatriate {
          id
          first_name
          marital {
            name
            id
          }
          father_name
          grand_father_name
          date_of_birth
          skills
          gender {
            name
            id
          }
          education_backgrounds {
            field_of_study
            review_status
            review_note
            received_date
            qualification_level {
              name
              id
            }
          }
          way_of_entry {
            name
          }
          nationality {
            name
          }
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
        }
        expatriate_work_permit_documents {
          document_type {
            id
          }
          id
          created_at
          files
          title
          review_status
          review_note
          properties
          document_type {
            name
          }
        }
        review_status
        reviewed_note
      }
    }
  }
`;

export const SEARCH_EXPATIATES_PERMITS_FILTER_DOCUMENT_CHECKER_FINANCE = gql`
  query SEARCH_EXPATIATES_PERMITS_FILTER_DOCUMENT_CHECKER_FINANCE(
    $employeeID: uuid = ""
    $limit: Int = 10
    $offset: Int = 0
    $searchVal: String
    $permit: uuid = "f4f0b403-3c62-4890-8700-8c25ff87c05d"
  ) {
    assigned_workpermit_reviewers(
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
      where: {
        employee_id: { _eq: $employeeID }
        expatriate_work_permit: { permit_type: { _eq: $permit } }
        _or: [
          {
            expatriate_work_permit: {
              expatriate: { first_name: { _iregex: $searchVal } }
            }
          }
          {
            expatriate_work_permit: {
              expatriate: { passport_number: { _ilike: $searchVal } }
            }
          }
          {
            expatriate_work_permit: { reference_number: { _ilike: $searchVal } }
          }
          {
            expatriate_work_permit: {
              organization: { tin_number: { _ilike: $searchVal } }
            }
          }
        ]
      }
    ) {
      id
      expatriate_work_permit {
        id
        allowance
        created_at
        document_status
        reference_number
        document_checker_status
        expatriate_work_permit_documents {
          review_status
          properties
          title
          id
          review_note
          files
          document_type {
            name
          }
        }
        expatriate {
          id
          first_name
          marital {
            name
            id
          }
          father_name
          grand_father_name
          date_of_birth
          skills
          gender {
            name
            id
          }
          education_backgrounds {
            field_of_study
            review_status
            review_note
            received_date
            qualification_level {
              name
              id
            }
          }
          way_of_entry {
            name
          }
          nationality {
            name
          }
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
        }
        expatriate_work_permit_documents {
          document_type {
            id
          }
          id
          created_at
          files
          title
          review_status
          review_note
          properties
          document_type {
            name
          }
        }
        review_status
        reviewed_note
      }
    }
  }
`;

export const SEARCH_WORK_PERMITS_FILTER = gql`
  query SEARCH_WORK_PERMITS_FILTER(
    $limit: Int = 10
    $offset: Int = 0
    $searchValue: String
    $permit: uuid = "f4f0b403-3c62-4890-8700-8c25ff87c05d"
  ) {
    expatriate_work_permits(
      order_by: { emr_number: desc }
      limit: $limit
      offset: $offset
      where: {
        _or: [
          { emr_number: { _ilike: $searchValue } }
          { expatriate: { passport_number: { _ilike: $searchValue } } }
          { expatriate: { first_name: { _ilike: $searchValue } } }
        ]
      } # where: { #   permit_type: { _eq: $permit } #   emr_number: {_ilike: $searchValue} # }
    ) {
      id
      emr_number
      reference_number
      reviewed_note
      document_status
      assigned_workpermit_reviewers {
        created_at
        employee {
          user_info {
            name
          }
        }
      }
      allowance
      created_by
      updated_by
      updated_at
      created_at
      work_permit_status
      start_date
      salary
      reviewed_note
      reviewed_by_info {
        name
      }
      review_status
      expatriate_work_permit_type {
        name
      }
      expatriate {
        first_name
        father_name
        id
        created_at
      }
      organization {
        name
        tin_number
        email
        business_licence_number
        organization_type {
          name
        }
      }
      occupation_category {
        name
      }
      issued_date
      # expiry_status
      expatriate_work_permit_documents {
        document_type {
          id
          __typename
        }
        id
        created_at
        files
        title
        review_status
        review_note
        properties
        document_type {
          name
          __typename
        }
        __typename
      }
    }
  }
`;

export const APPROVED_EXPATRATES_PERMITS_FILTER_SEARCH = gql`
  query filter_verified_account(
    $searchInput: String # $searchLabel: String
  ) {
    expatriate_work_permits(
      order_by: { updated_at: desc }
      where: {
        document_checker_status: { _eq: true }
        # emr_number: { _neq: "EMR126222" }
        _or: [
          { expatriate: { passport_number: { _ilike: $searchInput } } }
          { organization: { tin_number: { _ilike: $searchInput } } }
          { expatriate: { first_name: { _ilike: $searchInput } } }
          { expatriate: { father_name: { _ilike: $searchInput } } }
        ]
      }
    ) {
      id
      emr_number
      immigration_letter
      reference_number
      reviewed_note
      document_status
      assigned_workpermit_reviewers {
        created_at
        employee {
          user_info {
            name
          }
        }
      }
      allowance
      created_by
      updated_by
      work_permit_status
      start_date
      salary
      reviewed_note
      reviewed_by_info {
        name
      }
      review_status
      expatriate_work_permit_type {
        name
      }
      expatriate {
        first_name
        father_name
        id
        created_at
      }
      organization {
        name
        tin_number
        email
        business_licence_number
        organization_type {
          name
        }
      }
      occupation_category {
        name
      }
      issued_date
      expiry_status
      expatriate_work_permit_documents {
        document_type {
          id
          __typename
        }
        id
        created_at
        files
        title
        review_status
        review_note
        properties
        document_type {
          name
          __typename
        }
        __typename
      }
    }
  }
`;

export const APPROVED_EXPATRATES_PERMITS_COUNT = gql`
  query expatriate_work_permits_aggregate {
    expatriate_work_permits_aggregate(
      where: { document_checker_status: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ORG_EXPATRIATE_BY_ID = gql`
  query expatriates($id: uuid = "") {
    expatriates(where: { id: { _eq: $id } }) {
      created_at
      date_of_birth
      education_backgrounds {
        attachments
        field_of_study
        id
        review_status
        review_note
        received_date
        qualification_type {
          id
          name_json
          name
        }
        qualification_level {
          id
          name
          name_json
        }
        institution_name
      }
      expatriate_work_permits {
        allowance
        emr_number
        expatriate_work_permit_type {
          name
        }
        created_at
        end_date
        created_by
        expatriate_counterparts {
          assigned_date
          created_by
          first_name
          last_name
          position
        }
        expatriate_work_permit_documents {
          files
          id
          document_type {
            name_json
            id
          }
          properties
          review_status
          review_note
          title
        }
        start_date
        salary
        reviewed_note
        review_status
        occupation_category {
          id
          name_json
          name
        }
        issued_date
        id
        expiry_date
        expatriate_work_permit_type {
          name
        }
        expatriate_work_places {
          district {
            name_json
          }
          house_number
          kebele
          zone {
            name_json
          }
          subcity {
            name_json
          }
          region {
            name_json
          }
          project_name
          phone_number
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
          email
          business_licence_number
          tin_number
          phone_no
          district {
            name_json
          }
          zone {
            name_json
          }
          woreda {
            name_json
          }
          subcity {
            name_json
          }
          user_info {
            name
            phone_number
          }
        }
      }
      father_name
      first_name
      grand_father_name
      gender {
        name
      }
      marital {
        name
        id
      }
      id
      nationality {
        name
      }
      visa_type {
        id
        name
      }
      way_of_entry {
        name
      }
      skills
      profession_title {
        id
        name_json
      }
      passport_number
      visa_type
      visa_number
    }
  }
`;

export const PERMIT_PRICE = gql`
  query permit_price {
    base_expatriate_work_permit_prices {
      id
      price
      work_permit_type {
        name
      }
    }
  }
`;

// =========================================================SEARCH EXPT BY ORG======================================
export const EXPAT_BY_ORGANZATION = gql`
  query filterpermitList(
    $limit: Int = 10
    $offset: Int = 0
    $orgID: uuid = ""
    $permitType: uuid = "8989ab07-2f8d-4a5d-b161-0e08cfa14299"
  ) {
    expatriate_work_permits(
      order_by: { created_at: desc }
      where: {
        organization_id: { _eq: $orgID }
        permit_type: { _eq: $permitType }
      }
      limit: $limit
      offset: $offset
    ) {
      allowance
      reference_number
      emr_number
      assigned_workpermit_reviewers {
        employee {
          user_info {
            name
            phone_number
            allowed_roles {
              role_information {
                id
                value
              }
            }
          }
        }
        status
        created_at
      }
      work_permit_black_list {
        approved_by
        approved_on
        id
        requested_on
        request_note
        requested_by
      }
      created_at
      end_date
      created_by
      expatriate_counterparts {
        assigned_date
        created_by
        first_name
        last_name
        position
      }
      expatriate_work_permit_documents {
        files
        id
        created_at
        document_type {
          name_json
          id
        }
        properties
        review_status
        review_note
        title
      }
      start_date
      salary
      reviewed_note
      review_status
      occupation_category {
        name_json
        name
        id
      }
      issued_date
      id
      expiry_date
      expatriate_work_permit_type {
        name
      }
      expatriate_work_places {
        district {
          name_json
        }
        house_number
        kebele
        zone {
          name_json
        }
        subcity {
          name_json
        }
        region {
          name_json
        }
        project_name
        phone_number
      }
      organization {
        id
        name
        sector {
          name_json
        }
        organization_type {
          name_json
        }
        email
        business_licence_number
        tin_number
        district {
          name_json
        }
        zone {
          name_json
        }
        woreda {
          name_json
        }
        subcity {
          name_json
        }
        user_info {
          name
          phone_number
        }
      }
      expatriate {
        father_name
        first_name
        grand_father_name
        gender {
          name
        }
        marital {
          name
        }
        id
        nationality {
          name
        }
        visa_type {
          id
          name
        }
        way_of_entry {
          name
        }
        skills
        profession_title {
          id
          name_json
        }
        id
        passport_number
        visa_type {
          id
        }
        visa_number
        created_at
        date_of_birth
        education_backgrounds {
          attachments
          field_of_study
          id
          review_status
          review_note
          received_date
          qualification_type {
            id
            name_json
            name
          }
          qualification_level {
            id
            name
            name_json
          }
          institution_name
        }
      }
    }
  }
`;

export const GET_ASSIGNED_ORGANIZATION_LIST = gql`
  query get_assgined_organization($empID: uuid = "") {
    assigned_organization_reviewers(
      order_by: { created_at: desc }
      where: { employee_id: { _eq: $empID } }
    ) {
      id
      employee_id
      organization {
        business_licence_number
        document_status
        created_at
        user_info {
          id
          email
          name
        }
        district {
          id
          name_json
        }
        email
        expatriates_count
        fax
        local_employees_count
        house_number
        id
        zone {
          id
          name_json
        }
        woreda {
          id
          name_json
        }
        tin_number
        subcity {
          id
          name_json
        }
        sector {
          id
          name_json
        }
        expatriate_work_permit {
          expatriate {
            first_name
            passport_number
            father_name
            gender {
              name
            }
            marital {
              name
            }
          }
          expatriate_work_permit_documents {
            id
            document_type {
              name
              id
            }
            review_note
            review_status
          }
        }
        organization_documents {
          files
          review_status
          review_note
        }
        review_status
        review_note
        region {
          id
          name_json
        }
        organization_type {
          name_json
          id
        }
        name
        pobox
        phone_no
      }
    }
  }
`;

export const GET_SEARCH_ASSIGNED_WORK_PERMITS = gql`
  query get_all_assigned_work_permit(
    $employeeID: uuid = ""
    $orgID: uuid = ""
  ) {
    assigned_workpermit_reviewers(
      where: {
        employee_id: { _eq: $employeeID }
        expatriate_work_permit: { organization_id: { _eq: $orgID } }
      }
    ) {
      id
      expatriate_work_permit {
        id
        allowance
        created_at
        document_status
        reference_number
        expatriate_work_permit_documents {
          review_status
          properties
          title
          id
          review_note
          files
          document_type {
            name
          }
        }
        expatriate {
          id
          first_name
          marital {
            name
            id
          }
          father_name
          grand_father_name
          date_of_birth
          skills
          gender {
            name
            id
          }
          education_backgrounds {
            field_of_study
            review_status
            review_note
            received_date
            qualification_level {
              name
              id
            }
          }
          way_of_entry {
            name
          }
          nationality {
            name
          }
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
        }
        expatriate_work_permit_documents {
          document_type {
            id
          }
          id
          created_at
          files
          title
          review_status
          review_note
          properties
          document_type {
            name
          }
        }
        review_status
        reviewed_note
      }
    }
  }
`;

// =======================================================SEARCH DYNAMIC GRAPHQL==================================
export const SEARCH_WORK_PERMIT = gql`
  query search_work_permit(
    $searchInput: String = ""
    $limit: Int = 10
    $offset: Int = 0
  ) {
    expatriates(
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
      where: {
        _or: [
          { expatriate_work_permits: { emr_number: { _ilike: $searchInput } } }
          {
            expatriate_work_permits: {
              reference_number: { _ilike: $searchInput }
            }
          }
          { passport_number: { _ilike: $searchInput } }
          { first_name: { _ilike: $searchInput } }
        ]
      }
    ) {
      created_at
      date_of_birth
      education_backgrounds {
        attachments
        field_of_study
        id
        review_status
        review_note
        received_date
        qualification_type {
          id
          name_json
          name
        }
        qualification_level {
          id
          name
          name_json
        }
        institution_name
      }
      expatriate_work_permits {
        allowance
        reference_number
        emr_number
        assigned_workpermit_reviewers {
          employee {
            user_info {
              name
              phone_number
              allowed_roles {
                role_information {
                  id
                  value
                }
              }
            }
          }
          status
          created_at
        }
        work_permit_black_list {
          approved_by
          approved_on
          id
          requested_on
          request_note
          requested_by
        }
        created_at
        end_date
        created_by
        expatriate_counterparts {
          assigned_date
          created_by
          first_name
          last_name
          position
        }
        expatriate_work_permit_documents {
          files
          id
          created_at
          document_type {
            name_json
            id
          }
          properties
          review_status
          review_note
          title
        }
        start_date
        salary
        reviewed_note
        review_status
        occupation_category {
          name_json
          name
        }
        issued_date
        id
        expiry_date
        expatriate_work_permit_type {
          name
        }
        expatriate_work_places {
          district {
            name_json
          }
          house_number
          kebele
          zone {
            name_json
          }
          subcity {
            name_json
          }
          region {
            name_json
          }
          project_name
          phone_number
        }
        organization {
          id
          name
          sector {
            name_json
          }
          organization_type {
            name_json
          }
          email
          business_licence_number
          tin_number
          district {
            name_json
          }
          zone {
            name_json
          }
          woreda {
            name_json
          }
          subcity {
            name_json
          }
          user_info {
            name
            phone_number
          }
        }
      }
      father_name
      first_name
      grand_father_name
      gender {
        name
      }
      marital {
        name
      }
      id
      nationality {
        name
      }
      visa_type {
        id
        name
      }
      way_of_entry {
        name
      }
      skills
      profession_title {
        id
        name_json
      }
      id
      passport_number
      visa_type
      visa_number
    }
  }
`;

export const SEARCH_ORGANIZATION = gql`
  query search_organization(
    $searchInput: String = ""
    $limit: Int = 10
    $offset: Int
  ) {
    organizations(
      order_by: { created_at: desc }
      limit: $limit
      offset: $offset
      where: {
        _or: [
          { tin_number: { _ilike: $searchInput } }
          { name: { _ilike: $searchInput } }
        ]
      }
    ) {
      assigned_organization_reviewers {
        id
      }
      business_licence_number
      created_at
      user_info {
        id
        email
        name
      }
      district {
        id
        name_json
      }
      email
      expatriates_count
      fax
      local_employees_count
      house_number
      id
      zone {
        id
        name_json
      }
      woreda {
        id
        name_json
      }
      tin_number
      subcity {
        id
        name_json
      }
      sector {
        id
        name_json
      }
      expatriate_work_permit {
        expatriate {
          first_name
          passport_number
          father_name
          gender {
            name
          }
          marital {
            name
          }
        }
        expatriate_work_permit_documents {
          id
          document_type {
            name
            id
          }
          review_note
          review_status
        }
      }
      organization_documents {
        files
        review_status
        review_note
      }
      review_status
      review_note
      region {
        id
        name_json
      }
      organization_type {
        name_json
        id
      }
      name
      pobox
      phone_no
    }
  }
`;

export const SEARCH_SYS_EXPAT = gql`
  query SEARCH_SYS_EXPAT($pass: String) {
    expatriates(where: { passport_number: { _eq: $pass } }) {
      id
      first_name
      father_name
      grand_father_name
      passport_number
      date_of_birth
      expatriate_work_permits {
        passport_expiry_date
        passport_issued_date
        emr_number
        start_date
        end_date
        expatriate_work_permit_type {
          name
        }
      }
    }
  }
`;

// =============================================================================
// EMPLOYEE STATUS

export const EMPLOYEE_STATUS = gql`
  query EMPLOYEE_STATUS($userID: uuid) {
    employees(where: { user_id: { _eq: $userID } }) {
      assigned_organization_reviewers_aggregate {
        aggregate {
          count
        }
      }
      new: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      renewal: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      cancellation: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      replacement: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            permit_type: { _eq: "94040d3a-eca7-4f26-b7d7-18811ead8090" }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      total_assigned_permits: assigned_workpermit_reviewers_aggregate {
        aggregate {
          count
        }
      }
      active_status
      user_info {
        email
        name
        allowed_roles {
          role_information {
            value
          }
        }
      }
    }
  }
`;

/**
 * @description GET EMployee Weekly Stat
 */
export const EMPLOYEE_WEEKLY_STAT = gql`
  query EMPLOYEE_WEEKLY_STAT(
    $userID: uuid
    $weekStartDate: timestamptz
    $weekEndDate: timestamptz
  ) {
    employees(where: { user_id: { _eq: $userID } }) {
      assigned_organization_reviewers_aggregate {
        aggregate {
          count
        }
      }
      new_applicant_pending: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
            {
              expatriate_work_permit: {
                document_checker_status: { _is_null: true }
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      new_applicant_approved: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
            {
              expatriate_work_permit: { document_checker_status: { _eq: true } }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      new_applicant_rejected: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
            {
              expatriate_work_permit: {
                document_checker_status: { _eq: false }
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      renewal_pending: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
            {
              expatriate_work_permit: {
                document_checker_status: { _is_null: true }
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      renewal_approved: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
            {
              expatriate_work_permit: { document_checker_status: { _eq: true } }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      renewal_rejected: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
            {
              expatriate_work_permit: {
                document_checker_status: { _eq: false }
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      cancellation_pending: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
            {
              expatriate_work_permit: {
                document_checker_status: { _is_null: true }
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      cancellation_approved: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
            {
              expatriate_work_permit: { document_checker_status: { _eq: true } }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      cancellation_rejected: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
            {
              expatriate_work_permit: {
                document_checker_status: { _eq: false }
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      replacement: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "94040d3a-eca7-4f26-b7d7-18811ead8090" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      total_assigned_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      active_status
      user_info {
        email
        name
        allowed_roles {
          role_information {
            value
          }
        }
      }
    }
  }
`;

/**
 * @description generate general report on each member performance
 */
export const GENERATE_REPORT = gql`
  query GENERATE_REPORT(
    $weekStartDate: timestamptz
    $weekEndDate: timestamptz
  ) {
    employees(
      where: {
        user_info: {
          allowed_roles: {
            _or: [
              { role_id: { _eq: "06b85061-a3c6-40b5-bcbf-966c25bee615" } }
              { role_id: { _eq: "57ff8832-a6ca-4c5d-b053-57505591c457" } }
            ]
          }
        }
      }
    ) {
      user_info {
        name
        allowed_roles {
          role_information {
            value
          }
        }
      }
      total_assigned_org: assigned_organization_reviewers_aggregate(
        where: { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
      ) {
        aggregate {
          count
        }
      }
      total_assigned_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      total_new_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      total_renewal_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      total_cancellation_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      total_replacement_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          _and: [
            {
              expatriate_work_permit: {
                permit_type: { _eq: "94040d3a-eca7-4f26-b7d7-18811ead8090" }
              }
            }
            {
              expatriate_work_permit: {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
              }
            }
          ]
        }
      ) {
        aggregate {
          count
        }
      }

      approved_new_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            _and: [
              {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
                document_checker_status: { _eq: true }
                permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
              }
            ]
          }
        }
      ) {
        aggregate {
          count
        }
      }
      rejected_new_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            _and: [
              {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
                document_checker_status: { _eq: false }
                permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
              }
            ]
          }
        }
      ) {
        aggregate {
          count
        }
      }
      pending_new_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            _and: [
              {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
                document_checker_status: { _is_null: true }
                permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
              }
            ]
          }
        }
      ) {
        aggregate {
          count
        }
      }

      approved_renewal_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            _and: [
              {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
                document_checker_status: { _eq: true }
                permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
              }
            ]
          }
        }
      ) {
        aggregate {
          count
        }
      }
      rejected_renewal_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            _and: [
              {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
                document_checker_status: { _eq: false }
                permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
              }
            ]
          }
        }
      ) {
        aggregate {
          count
        }
      }

      pending_renewal_permits: assigned_workpermit_reviewers_aggregate(
        where: {
          expatriate_work_permit: {
            _and: [
              {
                created_at: { _gte: $weekStartDate, _lte: $weekEndDate }
                document_checker_status: { _is_null: true }
                permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
              }
            ]
          }
        }
      ) {
        aggregate {
          count
        }
      }

      active_status
      user_info {
        email
        name
        id
        allowed_roles {
          role_information {
            value
          }
        }
      }
    }
  }
`;

/**
 * @description Report count permits stat
 * @access wp:report
 */
export const GET_REPORT_COUNTS = gql`
  query GET_REPORT_COUNTS {
    total_permit: expatriate_work_permits_aggregate {
      aggregate {
        count
      }
    }
    document_checker_approved: expatriate_work_permits_aggregate(
      where: { document_checker_status: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    emr_approved_permits: expatriate_work_permits_aggregate(
      where: { review_status: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * @description report chart data
 * @access wp:report
 */
export const CHART_DATA_REPORT = gql`
  query CombinedChartData {
    new_permit: assigned_workpermit_reviewers_aggregate(
      where: {
        expatriate_work_permit: {
          permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    renewal_permit: assigned_workpermit_reviewers_aggregate(
      where: {
        expatriate_work_permit: {
          permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
        }
      }
    ) {
      aggregate {
        count
      }
    }

    cancellation_permit: assigned_workpermit_reviewers_aggregate(
      where: {
        expatriate_work_permit: {
          permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const EMR_DASHBOARD_REPORT = gql`
  query EMR_DASHBOARD_REPORT($employeeID: uuid) {
    total_renewalPermit: expatriate_work_permits_aggregate(
      where: {
        _and: [
          { updated_by: { _eq: $employeeID } }
          { permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_newPermit: expatriate_work_permits_aggregate(
      where: {
        _and: [
          { updated_by: { _eq: $employeeID } }
          { permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_cancelledPermit: expatriate_work_permits_aggregate(
      where: {
        _and: [
          { updated_by: { _eq: $employeeID } }
          { permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
export const EMR_DAILY_DASHBOARD_REPORT = gql`
  query EMR_DAILY_DASHBOARD_REPORT(
    $employeeID: uuid
    $weekStartDate: timestamptz
    $weekEndDate: timestamptz
  ) {
    total_assigned: expatriate_work_permits_aggregate(
      where: {
        _and: [
          { updated_by: { _eq: $employeeID } }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_renewalPermit: expatriate_work_permits_aggregate(
      where: {
        _and: [
          { updated_by: { _eq: $employeeID } }
          { permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" } }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_newPermit: expatriate_work_permits_aggregate(
      where: {
        _and: [
          { updated_by: { _eq: $employeeID } }
          { permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" } }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_cancelledPermit: expatriate_work_permits_aggregate(
      where: {
        _and: [
          { updated_by: { _eq: $employeeID } }
          { permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" } }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const CHECKER_DASHBOARD_REPORT = gql`
  query CHECKER_DASHBOARD_REPORT($employeeID: uuid) {
    total_newPermit: assigned_workpermit_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          {
            expatriate_work_permit: {
              permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_renewalPermit: assigned_workpermit_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          {
            expatriate_work_permit: {
              permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_cancelledPermit: assigned_workpermit_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          {
            expatriate_work_permit: {
              permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_organization: assigned_organization_reviewers_aggregate(
      where: { employee_id: { _eq: $employeeID } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const CHECKER_DAILY_REPORT = gql`
  query CHECKER_DAILY_REPORT(
    $employeeID: uuid
    $weekStartDate: timestamptz
    $weekEndDate: timestamptz
  ) {
    total_assigned: assigned_workpermit_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_newPermit: assigned_workpermit_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          {
            expatriate_work_permit: {
              permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
            }
          }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_renewalPermit: assigned_workpermit_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          {
            expatriate_work_permit: {
              permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
            }
          }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_cancelledPermit: assigned_workpermit_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          {
            expatriate_work_permit: {
              permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" }
            }
          }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_organization: assigned_organization_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const PERMIT_DASHBOARD_REPORT = gql`
  query PERMIT_DASHBOARD_REPORT {
    total_newPermit: expatriate_work_permits_aggregate(
      where: { permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" } }
    ) {
      aggregate {
        count
      }
    }
    total_renewalPermit: expatriate_work_permits_aggregate(
      where: { permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" } }
    ) {
      aggregate {
        count
      }
    }
    total_cancelledPermit: expatriate_work_permits_aggregate(
      where: { permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" } }
    ) {
      aggregate {
        count
      }
    }
    total_organization: organizations_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const PERMIT_DAILY_REPORT = gql`
  query PERMIT_DAILY_REPORT(
    $employeeID: uuid
    $weekStartDate: timestamptz
    $weekEndDate: timestamptz
  ) {
    total_assigned: assigned_workpermit_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_newPermit: assigned_workpermit_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          {
            expatriate_work_permit: {
              permit_type: { _eq: "8989ab07-2f8d-4a5d-b161-0e08cfa14299" }
            }
          }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_renewalPermit: assigned_workpermit_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          {
            expatriate_work_permit: {
              permit_type: { _eq: "f4f0b403-3c62-4890-8700-8c25ff87c05d" }
            }
          }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_cancelledPermit: assigned_workpermit_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          {
            expatriate_work_permit: {
              permit_type: { _eq: "ab997561-d4f2-4c22-a8bc-2dc1adc7168e" }
            }
          }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    total_organization: assigned_organization_reviewers_aggregate(
      where: {
        _and: [
          { employee_id: { _eq: $employeeID } }
          { created_at: { _gte: $weekStartDate, _lte: $weekEndDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
